import React from 'react';
import Layout from '../components/Layout/Layout';
import SEO from '../components/SEO';

function Process() {
  return (
    <Layout>
      <SEO title="Our Process" />
      <section className="inner-hero bg-lightgreen">
        <div className="container">
          <div className="inner-hero-content text-center">
            <h1 className="text-primary font-medium mb-2">Our Process</h1>
          </div>
        </div>
      </section>

      <section className="section-gap">
        <div className="container">
          <div className="sticky-product-features">
            <div className="row ">
              {[
                {
                  id: 0,
                  icon: '/img/receiving-prescription.svg',
                  title: 'Receiving your prescription',
                },
                {
                  id: 1,
                  icon: '/img/sorting.svg',
                  title: 'Sorting medicines using technology',
                },
                {
                  id: 2,
                  icon: '/img/packing.svg',
                  title: 'Packing them into pouches as per dosage',
                },
                {
                  id: 3,
                  icon: '/img/organising.svg',
                  title: 'Organizing pouches into a box',
                },
                {
                  id: 4,
                  icon: '/img/delivering.svg',
                  title: 'Delivering the box to your doorstep',
                },
              ].map(({ id, icon, title }) => (
                <div key={id} className="col col-4">
                  <div className="feature-single feature-text py-2 md:py-4">
                    <div className="feature-icon w-[88px] mb-1">
                      <img src={icon} alt={title} />
                    </div>
                    <h3 className="heading-smallest w-full text-primary mb-2 ">
                      {title}
                    </h3>

                    {/* <p className="max-w-[350px]">{description}</p> */}
                  </div>
                </div>
              ))}
            </div>
          </div>
        </div>
      </section>
    </Layout>
  );
}

export default Process;
